import React from 'react';
import { Eye, EyeOff } from 'lucide-react';

interface InputFieldProps {
  id: string;
  name: string;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  error?: string;
  autocomplete?: string;
  showPassword?: boolean;
  toggleShowPassword?: () => void;
}

export const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  className = '',
  required = false,
  error = '',
  autocomplete = 'off',
  showPassword,
  toggleShowPassword,
}) => {
  // Forza il type corretto per la password
  const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  return (
    <div className={`relative flex flex-col ${className} mb-2`}>
      <div className="relative">
        <input
          required={required}
          id={id}
          name={name}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`w-full rounded-xl border-2 p-2 pr-10 ${error ? 'border-red-500' : ''}`}
          autoComplete={autocomplete}
        />
        {(type === 'password' || type === 'text') && toggleShowPassword && (
          <button
            type="button"
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            onClick={toggleShowPassword}>
            {!showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        )}
      </div>
      {error && <span className="text-red-500 flex w-4/5 text-sm mt-1">{error}</span>}
    </div>
  );
};
