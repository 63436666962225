import React, { useEffect } from 'react';
import api from '../utils/api';
import { ReactComponent as Avatar } from '../ui/Avatar.svg';
import Calendario from './Calendar';
import { BookLessonData } from '../model/BookLessonData';
import { DateSelectArg } from '@fullcalendar/core';
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';
import { loadSvg } from '../utils/loadSvg';
import { ArrowBigLeft, BadgeEuro, Calendar, Clock, MapPin, User } from 'lucide-react';
import { motion } from 'framer-motion';
interface BookLessonProps {
  closeModal: () => void;
}

const BookLesson: React.FC<BookLessonProps> = ({ closeModal }) => {
  type steps =
    | 'selectChild'
    | 'selectSubject'
    | 'selectDuration'
    | 'selectLocation'
    | 'selectTeacher'
    | 'selectDate'
    | 'selectAvailableTeacher'
    | 'confirmFavoriteTeacher'
    | 'payment';

  const [step, setStep] = React.useState<steps>('selectChild');
  const [familyData, setFamilyData] = React.useState<any>();
  const [bookLessonData, setBookLessonData] = React.useState<BookLessonData>({} as BookLessonData);
  const [subjects, setSubjects] = React.useState<any[]>([]);
  const [durations, setDurations] = React.useState<any>([]);
  const [locations, setLocations] = React.useState<any[]>([]);
  const [teachers, setTeachers] = React.useState<any[]>(undefined);
  const [availableTeachers, setAvailableTeachers] = React.useState<any>({
    exactAvailabilities: [],
    nearAvailabilities: [],
  });
  const [teachersInfo, setTeachersInfo] = React.useState<any[]>(
    JSON.parse(localStorage.getItem('teachersInfo') || '[]')
  );
  const { setLoading } = useLoading();
  const { setError } = useError();
  const router = useNavigate();
  const [images, setImages] = React.useState<{ [key: string]: string }>({});

  const loadSubjectImages = async (subjectsData: any[]) => {
    const newImages: { [key: string]: string } = {};
    for (const subject of subjectsData) {
      newImages[subject.name] = await loadSvg(subject.name);
    }
    setImages(newImages);
  };

  useEffect(() => {
    console.log('sono nello useEffect: ', step);
    switch (step) {
      case 'selectChild':
        getFamilyInfo();
        break;
      case 'selectSubject':
        getSubjects();
        break;
      case 'selectDuration':
        getAvailableDuration();
        console.log('selectDuration');
        break;
      case 'selectLocation':
        getAvailableLocations();
        break;
      case 'selectTeacher':
        getFavoritesTeachers();
        break;
      case 'selectDate':
        console.log('selectDate');
        break;
      case 'selectAvailableTeacher':
        getAvailableTeachers();
        break;
      case 'confirmFavoriteTeacher':
        break;
      case 'payment':
        break;
    }
  }, [step]);

  useEffect(() => {
    console.log('familyDataSons: ', familyData);
    if (familyData && familyData.sons && familyData.sons.length === 1) {
      selectChild(0);
    }
  }, [familyData]);

  function getFamilyInfo() {
    if (!sessionStorage.getItem('familyData')) {
      setLoading(true);
      api
        .get('/family')
        .then((response) => {
          const familyData = response.data;
          console.log(familyData);
          setFamilyData({ ...familyData, status: undefined });
          sessionStorage.setItem('familyData', JSON.stringify({ ...familyData, status: undefined }));
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const familyData = JSON.parse(sessionStorage.getItem('familyData')!);
      console.log('familyData: ', familyData);
      setFamilyData(familyData);
    }
  }
  async function getSubjects() {
    let subjectsData;
    if (!sessionStorage.getItem('subjects')) {
      setLoading(true);
      api
        .get(`family/student/${bookLessonData.child.id}/subject`)
        .then(async (response) => {
          subjectsData = response.data.subjects;
          setSubjects(subjectsData);
          sessionStorage.setItem('subjects', JSON.stringify(subjectsData));
          await loadSubjectImages(subjectsData);
        })
        .catch((error) => {
          console.error(error);
          setError(true, error.response.data.message, error.response.status);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      subjectsData = JSON.parse(sessionStorage.getItem('subjects')!);
      setSubjects(subjectsData);
      await loadSubjectImages(subjectsData);
    }
  }
  async function getAvailableDuration() {
    setLoading(true);
    api
      .get(`lesson/available-lengths?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}`)
      .then((response) => {
        setDurations(response.data.lengths);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableLocations() {
    setLoading(true);
    api
      .get(
        `lesson/available-locations?subject_id=${bookLessonData.subject.id}&student_id=${bookLessonData.child.id}&lesson_length=${bookLessonData.duration}`
      )
      .then((response) => {
        setLocations(response.data.locations);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getFavoritesTeachers() {
    setLoading(true);
    api
      .get('family/teacher')
      .then((response) => {
        const teachers = response.data.teachers;
        console.log('teachers: ', teachers);
        if (teachers.length === 0) {
          setStep('selectDate');
          setTeachers(undefined);
          return;
        }
        setTeachers(teachers);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function getAvailableTeachers() {
    setLoading(true);
    try {
      const availabilityRequest = {
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        location: bookLessonData.location,
        required_lessons: [
          { start_date_time: bookLessonData.date.startStr, end_date_time: bookLessonData.date.endStr },
        ],
      };
      const response = await api.post('availability/search', availabilityRequest);
      const availabilities = response.data.availabilities[0];
      console.log('availabilities: ', availabilities);

      // Carica i teacherInfo già memorizzati in localStorage
      const storedTeachersInfo = localStorage.getItem('teachersInfo');
      let teachersInfo = storedTeachersInfo ? JSON.parse(storedTeachersInfo) : [];

      // Combina le disponibilità esatte e vicine
      const allAvailabilities = [...availabilities.exact_availabilities, ...availabilities.near_availabilities];

      // Trova gli ID degli insegnanti unici
      const uniqueTeacherIdsSet = new Set(allAvailabilities.map((availability: any) => availability.teacher_id));
      const uniqueTeacherIds = Array.from(uniqueTeacherIdsSet);

      // Filtra gli ID degli insegnanti che non sono già memorizzati
      const missingTeacherIds = uniqueTeacherIds.filter(
        (teacherId) => !teachersInfo.some((teacher: any) => teacher.id === teacherId)
      );

      // Ottieni le informazioni degli insegnanti mancanti
      const newTeacherInfosPromises = missingTeacherIds.map(async (teacherId) => {
        const response = await api.get(`family/teacher/${teacherId}`);
        return response.data.teacher;
      });

      const newTeacherInfos = await Promise.all(newTeacherInfosPromises);

      // Aggiorna la lista degli insegnanti e memorizza in localStorage se necessario
      if (newTeacherInfos.length > 0) {
        teachersInfo = [...teachersInfo, ...newTeacherInfos];
        setTeachersInfo(teachersInfo);
        localStorage.setItem('teachersInfo', JSON.stringify(teachersInfo));
      }

      // Imposta gli insegnanti disponibili
      setAvailableTeachers({
        exactAvailabilities: availabilities.exact_availabilities,
        nearAvailabilities: availabilities.near_availabilities,
      });
    } catch (error) {
      console.error(error);
      setError(true, error.response.data.message, error.response.status);
    } finally {
      setLoading(false);
    }
  }

  function selectChild(index: number) {
    setBookLessonData({ ...bookLessonData, child: familyData?.sons[index] });
    setStep('selectSubject');
  }

  function selectSubject(subject: any) {
    setBookLessonData({ ...bookLessonData, subject });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectDuration');
  }
  function selectDuration(duration: number) {
    setBookLessonData({ ...bookLessonData, duration });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectLocation');
  }
  function selectLocation(location: string) {
    setBookLessonData({ ...bookLessonData, location });
    console.log('bookLessonData: ', bookLessonData);
    setStep('selectTeacher');
  }
  function selectTeacher(teacher?: any) {
    setBookLessonData({ ...bookLessonData, teacher });
    console.log("bookLessonData dopo la scelta dell'insegnante: ", bookLessonData);
    setStep('selectDate');
  }

  function goBack() {
    switch (step) {
      case 'selectSubject':
        setStep('selectChild');
        break;
      case 'selectDuration':
        setStep('selectSubject');
        break;
      case 'selectLocation':
        setStep('selectDuration');
        break;
      case 'selectTeacher':
        setStep('selectLocation');
        break;
      case 'selectDate':
        teachers ? setStep('selectTeacher') : setStep('selectLocation');
        break;
      case 'confirmFavoriteTeacher':
        setStep('selectDate');
        break;
      case 'selectAvailableTeacher':
        setStep('selectDate');
        break;
    }
  }

  function handleDateSelect(selectInfo: DateSelectArg) {
    const selectedDateStart = new Date(selectInfo.startStr);
    console.log('selectedDateStart: ', selectedDateStart);
    const selectedDateEnd = new Date(selectInfo.startStr);
    console.log('selectedDateEnd: ', selectedDateEnd);
    selectedDateEnd.setMinutes(selectedDateEnd.getMinutes() + bookLessonData.duration * 60);

    // Ensure the selected dates are in local time
    const selectedDateStartLocal = new Date(
      selectedDateStart.getTime() - selectedDateStart.getTimezoneOffset() * 60000
    );
    const selectedDateEndLocal = new Date(selectedDateEnd.getTime() - selectedDateEnd.getTimezoneOffset() * 60000);

    selectInfo.end = selectedDateEnd;
    selectInfo.endStr = selectedDateEndLocal.toISOString().slice(0, 19).replace('T', ' ');
    selectInfo.startStr = selectedDateStartLocal.toISOString().slice(0, 19).replace('T', ' ');

    console.log('selectInfo: ', selectInfo);
    setBookLessonData({ ...bookLessonData, date: selectInfo });
    if (bookLessonData.teacher) {
      setStep('confirmFavoriteTeacher');
    } else {
      setStep('selectAvailableTeacher');
    }
  }

  function goToStripe(availability?: any) {
    closeModal();
    setLoading(true);

    const payload = availability
      ? {
          start_date_time: availability.start_date_time,
          location: bookLessonData.location,
          subject_id: bookLessonData.subject.id,
          teacher_id: availability.teacher_id,
          student_id: bookLessonData.child.id,
          size: bookLessonData.duration,
        }
      : {
          start_date_time: bookLessonData.date.startStr,
          location: bookLessonData.location,
          subject_id: bookLessonData.subject.id,
          teacher_id: bookLessonData.teacher.id,
          student_id: bookLessonData.child.id,
          size: bookLessonData.duration,
        };
    api
      .post('lesson/single', payload)
      .then((response) => {
        console.log('response: ', response.data);
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function TeacherCard({ teacher, availability, goToStripe, isNearAvailability = false }) {
    console.log('teacher: ', teacher);
    return (
      <div className="flex flex-col border-2 rounded-lg p-4 bg-white mb-4 hover:shadow-lg hover:border-fpcred transition-shadow duration-300">
        <div className="flex gap-4">
          <div className="flex flex-col justify-start w-full md:w-1/3 h-full">
            <div className="h-full md:max-h-[60%]">
              <User className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row gap-2 mb-2">
              <p className="text-xl font-semibold">{teacher.first_name}</p>
              <p className="text-xl font-semibold">{teacher.last_name}</p>
            </div>
            {isNearAvailability && (
              <div className="bg-[#f3e8e8] text-[#d10c33] font-bold px-4 py-2 text-center md:max-w-[20%] rounded-full text-sm">
                {new Date(availability.start_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                /
                {new Date(availability.end_date_time).toLocaleTimeString('it-IT', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
            )}
            <div className="mb-4">
              <p className="text-base">{teacher.bio ? teacher.bio : 'Nessuna bio disponibile'}</p> {/* Mostra la bio */}
            </div>
            <div>
              <button
                className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300"
                onClick={() => goToStripe(availability)}>
                Prenota
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 h-full">
      {step !== 'selectChild' && !(step === 'selectSubject' && familyData.sons.length === 1) && (
        <div className="w-16 h-12 pl-4">
          <ArrowBigLeft className="cursor-pointer w-full h-full text-fpcred" onClick={goBack} />
        </div>
      )}
      {step === 'selectChild' && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Per chi vuoi prenotare la lezione ?</h3>
          <div className="flex flex-col items-center justify-center gap-3 p-5">
            {familyData?.sons.map((son: any, index: number) => (
              <div
                key={index}
                className="flex p-4 rounded-lg border-y-2 border-y-fpcred gap-16 items-center w-full md:w-96 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
                onClick={() => selectChild(index)}>
                <Avatar />
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col">
                    <p className="text-base">Nome</p>
                    <span className="text-xl">{son.first_name}</span>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-base">Cognome</p>
                    <span className="text-xl">{son.last_name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectSubject' && subjects && (
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl text-center mb-4">Di quale materia ha bisogno?</h3>
          <div className="grid grid-cols-2 gap-6 p-5 justify-center">
            {subjects.map((subject: any, index: number) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center p-4 rounded-lg bg-fpcwhite w-36 h-36 md:w-48 md:h-48 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg hover:border-fpcred border-2"
                onClick={() => selectSubject(subject)}>
                <img src={images[subject.name]} alt={subject.name} className="w-24 h-24 md:w-32 md:h-32 mb-2" />
                <div className="text-center">
                  <span className="text-xl">{subject.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {step === 'selectDuration' && durations && (
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-2xl text-center">Quanto tempo vuoi prenotare?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {durations.map((duration, index) => (
              <div
                key={index}
                className={`flex items-center justify-center p-2 rounded-xl bg-fpcwhite w-36 h-36 shadow-md transition duration-300 ease-in-out border-2 ${
                  duration.enabled
                    ? 'hover:border-fpcred hover:scale-105 hover:shadow-lg cursor-pointer'
                    : 'opacity-50 cursor-not-allowed'
                }`}
                onClick={() => {
                  if (duration.enabled) {
                    selectDuration(duration.length);
                  }
                }}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-2xl">
                      {duration.length === 1 && '1 ora'}
                      {duration.length === 1.5 && '1 ora e mezza'}
                      {duration.length === 2 && '2 ore'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {step === 'selectLocation' && locations && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Dove vuoi fare la lezione?</h3>
          <div className="flex flex-wrap justify-around gap-6 p-5">
            {Object.entries(locations).map((location, index) => (
              <div
                key={index}
                className={`flex items-center justify-center p-4 rounded-lg border-2 bg-fpcwhite w-36  ${
                  location[1].available
                    ? 'cursor-pointer hover:border-fpcred shadow-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg'
                    : 'opacity-25 cursor-not-allowed'
                }`}
                onClick={() => location[1].available && selectLocation(location[0])}>
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{location[0]}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {step === 'selectTeacher' && teachers && (
        <div className="flex flex-col">
          <h3 className="text-xl text-center">Quale insegnante vuoi prenotare?</h3>
          <div className="flex justify-center gap-6 p-5">
            {teachers.map((teacher: any, index: number) => (
              <div
                key={index}
                onClick={() => selectTeacher(teacher)}
                className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
                <div className="flex flex-col items-center justify-center h-full w-full">
                  <div className="text-center">
                    <span className="text-xl">{teacher.first_name}</span>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="flex items-center justify-center p-4 rounded-lg border border-black w-36 shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
              onClick={() => selectTeacher()}>
              <div className="flex flex-col items-center justify-center h-full w-full">
                <div className="text-center">
                  <span className="text-xl">Nessuna preferenza</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 'selectDate' && <Calendario bookLessonData={bookLessonData} onDateSelect={handleDateSelect} />}
      {step === 'confirmFavoriteTeacher' && (
        <div>
          <h1 className="text-3xl font-bold mb-8 text-center text-[#d10c33]">Conferma Prenotazione</h1>
          {/* Riepilogo Lezione (a sinistra) */}
          <div className="">
            <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
              <div className="bg-fpcwhite rounded-lg p-6 shadow-md flex gap-4">
                <div className="w-full">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Riepilogo Lezione</h2>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {bookLessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.date.start.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}/
                        {bookLessonData.date.end.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.duration === 1 && '25 €'}
                        {bookLessonData.duration === 1.5 && '37,5 €'}
                        {bookLessonData.duration === 2 && '50 €'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" p-4 w-full">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnante Preferito</h2>
                  <div className="flex">
                    <Avatar />
                    <div className="flex flex-col ml-4">
                      <p className="text-xl font-semibold">{bookLessonData.teacher.first_name}</p>
                      <p className="text-xl font-semibold">{bookLessonData.teacher.last_name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="bg-[#d10c33] px-4 py-2 rounded-xl text-white hover:bg-[#d10c33]/90 transition-colors duration-300"
                  onClick={() => goToStripe()}>
                  Vai al pagamento
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}
      {step === 'selectAvailableTeacher' && availableTeachers && (
        <div className="container mx-auto max-w-7xl overflow-auto">
          <h1 className="text-3xl font-bold mb-8 text-center text-[#d10c33]">Conferma Prenotazione</h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Riepilogo Lezione (a sinistra) */}
            <div className="lg:col-span-1">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}>
                <div className="bg-fpcwhite rounded-lg p-6 shadow-md">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Riepilogo Lezione</h2>
                  <div className="space-y-4">
                    <div className="flex items-center">
                      <Calendar className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.date.start.toLocaleDateString('it-IT')}</span>
                      <span className="ml-2">
                        {bookLessonData.date.start
                          .toLocaleDateString('it-IT', { weekday: 'long' })
                          .charAt(0)
                          .toUpperCase() +
                          bookLessonData.date.start.toLocaleDateString('it-IT', { weekday: 'long' }).slice(1)}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.date.start.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}/
                        {bookLessonData.date.end.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <MapPin className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>{bookLessonData.location}</span>
                    </div>
                    <div className="flex items-center">
                      <BadgeEuro className="mr-2 h-5 w-5 text-[#d10c33]" />
                      <span>
                        {bookLessonData.duration === 1 && '25 €'}
                        {bookLessonData.duration === 1.5 && '37,5 €'}
                        {bookLessonData.duration === 2 && '50 €'}
                      </span>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Lista Insegnanti (a destra) */}
            <div className="lg:col-span-2">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}>
                <div className="bg-fpcwhite rounded-lg p-2 md:p-6 shadow-md">
                  <h2 className="text-2xl font-bold text-[#d10c33] mb-4">Insegnanti Disponibili</h2>
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Disponibilità per l'orario richiesto</h3>
                      <div className="space-y-4 max-h-96 overflow-y-auto">
                        {availableTeachers.exactAvailabilities.map((availability, index) => {
                          const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                          return teacher ? (
                            <TeacherCard
                              key={index}
                              teacher={teacher}
                              availability={availability}
                              goToStripe={goToStripe}
                            />
                          ) : null;
                        })}
                      </div>
                    </div>

                    <div>
                      <h3 className="text-xl font-semibold mb-2">Disponibilità vicino all'orario richiesto</h3>
                      <div className="space-y-4 max-h-96 overflow-y-auto">
                        {availableTeachers.nearAvailabilities.map((availability, index) => {
                          const teacher = teachersInfo.find((teacher) => teacher.id === availability.teacher_id);
                          return teacher ? (
                            <TeacherCard
                              key={index}
                              teacher={teacher}
                              availability={availability}
                              goToStripe={goToStripe}
                              isNearAvailability
                            />
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookLesson;
