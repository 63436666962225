import React, { useState, useEffect } from 'react';
import { Bell, BellDot } from 'lucide-react';
import { motion } from 'framer-motion';
import api from '../utils/api';
import BookLesson from './BookLesson';

interface Props {
  page: string;
}

interface Notification {
  id: number;
  type: string;
  sender_id: number;
  recipient_id: number;
  status: string;
  metadata: {
    new_lesson_id: number;
    old_lesson_id: number;
    lesson_id: number;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  accept_link: string | null;
  reject_link: string | null;
  notificationDetails?: {
    phone: string;
    email: string;
    price: number;
    duration: number;
    subject: number;
    location: string;
    teacherName: string;
    teacherLastName: string;
    studentName: string;
    studentLastName: string;
    new_start_time: string;
    new_end_time: string;
    old_start_time: string;
    old_end_time: string;
  };
}

interface NotificationResponse {
  status: string;
  notifications: Notification[];
  pagination: {
    current_page: number;
    page_count: number;
    page_size: number;
    total: number;
  };
}

const GenericComponent: React.FC<Props> = ({ page }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isAccepted, setIsAccepted] = useState(false);

  const toggleExpansion = () => {
    if (isExpanded) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 600);
    }
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    api
      .get<NotificationResponse>('/notification')
      .then((response) => {
        console.log('le mie notifiche', response.data);
        setNotifications(response.data.notifications);
        const notificationsWithMetadata = response.data.notifications.filter(
          (notification) =>
            notification.metadata &&
            (notification.metadata.new_lesson_id ||
              notification.metadata.old_lesson_id ||
              notification.metadata.lesson_id)
        );

        notificationsWithMetadata.forEach((notification) => {
          const lessonOldId = notification.metadata.old_lesson_id;
          const lessonNewId = notification.metadata.new_lesson_id;
          const lessonId = notification.metadata.lesson_id;

          if (lessonId) {
            api
              .get(`/lesson/${lessonId}`)
              .then((lessonResponse) => {
                console.log(`Dettagli della lezione ${lessonId} prenotata:`, lessonResponse.data);
                setNotifications((prevNotifications) => {
                  const updatedNotifications = prevNotifications.map((n) => {
                    if (n.metadata && n.metadata.lesson_id === lessonId) {
                      return {
                        ...n,
                        notificationDetails: {
                          ...n.notificationDetails,
                          email: lessonResponse.data.lesson.guardian.email,
                          phone: lessonResponse.data.lesson.guardian.phone,
                          price: lessonResponse.data.lesson.price,
                          studentName: lessonResponse.data.lesson.student.first_name,
                          studentLastName: lessonResponse.data.lesson.student.last_name,
                          duration: lessonResponse.data.lesson.size,
                          subject: lessonResponse.data.lesson.subject.name,
                          location: lessonResponse.data.lesson.location,
                          teacherName: lessonResponse.data.lesson.teacher.first_name,
                          teacherLastName: lessonResponse.data.lesson.teacher.last_name,
                          new_start_time: lessonResponse.data.lesson.start_time,
                          new_end_time: lessonResponse.data.lesson.end_time,
                        },
                      };
                    }
                    return n;
                  });
                  console.log('Notifiche aggiornate con i dettagli della lezione Prenotata:', updatedNotifications);
                  return updatedNotifications;
                });
              })
              .catch((error) => {
                console.error(`Errore nel recupero della lezione ${lessonOldId}`, error);
              });
          }

          if (lessonOldId) {
            api
              .get(`/lesson/${lessonOldId}`)
              .then((lessonResponse) => {
                console.log(`Dettagli della vecchia lezione ${lessonOldId}:`, lessonResponse.data);
                setNotifications((prevNotifications) => {
                  const updatedNotifications = prevNotifications.map((n) => {
                    if (n.metadata && n.metadata.old_lesson_id === lessonOldId) {
                      return {
                        ...n,
                        notificationDetails: {
                          ...n.notificationDetails,
                          email: lessonResponse.data.lesson.guardian.email,
                          phone: lessonResponse.data.lesson.guardian.phone,
                          price: lessonResponse.data.lesson.price,
                          studentName: lessonResponse.data.lesson.student.first_name,
                          studentLastName: lessonResponse.data.lesson.student.last_name,
                          duration: lessonResponse.data.lesson.size,
                          subject: lessonResponse.data.lesson.subject.name,
                          location: lessonResponse.data.lesson.location,
                          teacherName: lessonResponse.data.lesson.teacher.first_name,
                          teacherLastName: lessonResponse.data.lesson.teacher.last_name,
                          old_start_time: lessonResponse.data.lesson.start_time,
                          old_end_time: lessonResponse.data.lesson.end_time,
                        },
                      };
                    }
                    return n;
                  });
                  console.log('Notifiche aggiornate con i dettagli della vecchia lezione:', updatedNotifications);
                  return updatedNotifications;
                });
              })
              .catch((error) => {
                console.error(`Errore nel recupero della lezione ${lessonOldId}`, error);
              });
          }

          if (lessonNewId) {
            api
              .get(`/lesson/${lessonNewId}`)
              .then((lessonResponse) => {
                console.log(`Dettagli della Nuova lezione ${lessonNewId}:`, lessonResponse.data);
                setNotifications((prevNotifications) => {
                  const updatedNotifications = prevNotifications.map((n) => {
                    if (n.metadata && n.metadata.new_lesson_id === lessonNewId) {
                      return {
                        ...n,
                        notificationDetails: {
                          ...n.notificationDetails,
                          email: lessonResponse.data.lesson.guardian.email,
                          phone: lessonResponse.data.lesson.guardian.phone,
                          price: lessonResponse.data.lesson.price,
                          studentName: lessonResponse.data.lesson.student.first_name,
                          studentLastName: lessonResponse.data.lesson.student.last_name,
                          duration: lessonResponse.data.lesson.size,
                          subject: lessonResponse.data.lesson.subject.name,
                          location: lessonResponse.data.lesson.location,
                          teacherName: lessonResponse.data.lesson.teacher.first_name,
                          teacherLastName: lessonResponse.data.lesson.teacher.last_name,
                          new_start_time: lessonResponse.data.lesson.start_time,
                          new_end_time: lessonResponse.data.lesson.end_time,
                        },
                      };
                    }
                    return n;
                  });
                  console.log('Notifiche aggiornate con i dettagli della nuova lezione:', updatedNotifications);
                  return updatedNotifications;
                });
              })
              .catch((error) => {
                console.error(`Errore nel recupero della lezione ${lessonNewId}`, error);
              });
          }
        });
      })
      .catch((error) => {
        console.error('Errore nel recupero delle notifiche', error);
      });
  }, []);

  useEffect(() => {
    if (isExpanded) {
      const unreadNotifications = notifications.filter((notification) => notification.status === 'unread');
      unreadNotifications.forEach((notification) => {
        api
          .post(`/notification/${notification.id}/read`)
          .then((response) => {
            console.log(`Notifica ${notification.id} segnata come letta`, response.data);
            setNotifications((prevNotifications) =>
              prevNotifications.map((n) => (n.id === notification.id ? { ...n, status: 'read' } : n))
            );
          })
          .catch((error) => {
            console.error(`Errore nel segnare la notifica ${notification.id} come letta`, error);
          });
      });
    }
  }, [isExpanded, notifications]);

  // Filtra le notifiche da visualizzare, escludendo quelle accettate o rifiutate
  const visibleNotifications = notifications.filter(
    (notification) => notification.status !== 'accepted' && notification.status !== 'rejected'
  );

  const getNotificationMessage = (notification: Notification) => {
    const details = notification.notificationDetails;
    if (!details) {
      return 'Non ci sono i dettagli';
    }

    const formatDuration = (duration: number): string => {
      if (duration === 1) {
        return "un'ora";
      } else if (duration === 1.5) {
        return "un'ora e mezza";
      } else if (duration === 2) {
        return 'due ore';
      }
      return `${duration} ore`;
    };

    const startDate = new Date(details.new_start_time);
    const oldDate = new Date(details.old_start_time);

    const formattedDate = startDate.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'long',
    });

    const oldFormattedDate = oldDate.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'long',
    });

    const formattedTime = startDate.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const oldFormattedTime = oldDate.toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit',
    });

    switch (notification.type) {
      case 'new_single_lesson_booked_family':
        return (
          <>
            Lezione da {formatDuration(details.duration)} di {details.subject} prenotata con successo
            <br />
            Si svolgerà il {formattedDate} alle {formattedTime} {details.location} con {details.teacherName}{' '}
            {details.teacherLastName}
          </>
        );
      case 'new_single_lesson_booked_teacher':
        return (
          <>
            {details.studentName} {details.studentLastName} ha prenotato una lezione da{' '}
            {formatDuration(details.duration)} di {details.subject} il {formattedDate} alle {formattedTime}{' '}
            {details.location}
          </>
        );
      case 'single_lesson_deleted_from_teacher_family':
        return (
          <>
            La lezione da {formatDuration(details.duration)} di {details.subject} con {details.teacherName}{' '}
            {details.teacherLastName} prevista per il giorno {formattedDate} alle {formattedTime} è stata cancellata
            dall'insegnante. Abbiamo già provveduto ad emettere il rimborso di {details.price} euro
          </>
        );
      case 'single_lesson_deleted_from_teacher_teacher':
        return (
          <>
            La lezione da {formatDuration(details.duration)} di {details.subject} con {details.studentName}{' '}
            {details.studentLastName} prevista per il giorno {formattedDate} alle {formattedTime} è stata cancellata con
            successo. Stiamo provvedendo ad emettere il rimborso nei confronti della famiglia di {details.price} euro
          </>
        );
      case 'single_lesson_deleted_from_family_family':
        return (
          <>
            La lezione da {formatDuration(details.duration)} di {details.subject} con {details.teacherName}{' '}
            {details.teacherLastName} prevista per il giorno {formattedDate} alle {formattedTime} è stata cancellata con
            successo. Abbiamo già provveduto ad emettere il rimborso di {details.price} euro
          </>
        );
      case 'single_lesson_deleted_from_family_teacher':
        return (
          <>
            La lezione da {formatDuration(details.duration)} di {details.subject} con {details.studentName}{' '}
            {details.studentLastName} prevista per il giorno {formattedDate} alle {formattedTime} è stata cancellata
            dalla famiglia. La tua disponibilità è già stata ripristinata.
          </>
        );
      case 'lesson_modified_same_teacher_family':
        return (
          <>
            Modifica effettuata con successo. <br /> Lezione da {formatDuration(details.duration)} di {details.subject}{' '}
            si svolgerà il {formattedDate} alle {formattedTime}, {details.location}
          </>
        );
      case 'lesson_modified_same_teacher_teacher':
        return (
          <>
            Lezione con {details.studentName} {details.studentLastName} modifica. La lezione da{' '}
            {formatDuration(details.duration)} di {details.subject} il {formattedDate} alle {formattedTime},{' '}
            {details.location}
          </>
        );
      case 'lesson_modified_different_teacher_old_teacher':
        return (
          <>
            La lezione da {formatDuration(details.duration)} di {details.subject} con {details.studentName}{' '}
            {details.studentLastName} prevista per il {formattedDate} alle {formattedTime} è stata cancellata dalla
            famiglia. La tua disponibilità è già stata ripristinata.
          </>
        );
      case 'lesson_modified_different_teacher_new_teacher':
        return (
          <>
            {details.studentName} {details.studentLastName} ha prenotato una lezione da{' '}
            {formatDuration(details.duration)} di {details.subject} il {formattedDate} alle {formattedTime}{' '}
            {details.location}
          </>
        );
      case 'lesson_modified_different_teacher_family':
        return (
          <>
            Modifica effettuata con successo. <br /> Lezione da {formatDuration(details.duration)} di {details.subject}{' '}
            si svolgerà con {details.teacherName} {details.teacherLastName} il {formattedDate} alle {formattedTime},{' '}
            {details.location}
          </>
        );
      case 'lesson_modify_requested_from_teacher_family':
        return (
          <>
            L’insegnante {details.teacherName} {details.teacherLastName} richiede di modificare una lezione: <br />
            Lezione prenotata: il {oldFormattedDate} alle {oldFormattedTime} di {formatDuration(details.duration)},
            {details.location}. Nuova lezione: il {formattedDate} alle {formattedTime} di{' '}
            {formatDuration(details.duration)}, {details.location}.
          </>
        );
      case 'lesson_modify_requested_from_teacher_teacher':
        return (
          <>
            Hai richiesto a {details.studentName} {details.studentLastName} di modificare una lezione: Lezione
            prenotata: il {oldFormattedDate} alle {oldFormattedTime} di {formatDuration(details.duration)}
            Nuova lezione: il {formattedDate} alle {formattedTime} di {formatDuration(details.duration)},{' '}
            {details.location}. Riceverai una notifica che ti comunicherà l'esito della richiesta.
          </>
        );
      case 'lesson_modify_confirmed_family':
        return (
          <>
            Modifica effettuata con successo. La lezione con {details.teacherName} {details.teacherLastName} da{' '}
            {formatDuration(details.duration)} di {details.subject} si svolgerà il {formattedDate} alle ore{' '}
            {formattedTime}, {details.location}
          </>
        );
      case 'lesson_modify_confirmed_teacher':
        return (
          <>
            {details.studentName} {details.studentLastName} ha accettato la richiesta di modifica La lezione da{' '}
            {formatDuration(details.duration)} di {details.subject} si svolgerà il {formattedDate} alle ore{' '}
            {formattedTime}, {details.location}
          </>
        );
      case 'lesson_modified_rejected_family':
        return (
          <>
            Hai rifiutato la modifica richiesta da {details.teacherName} {details.teacherLastName}. Verrai contattato al
            più presto per ricevere una proposta alternativa
          </>
        );
      case 'lesson_modified_rejected_teacher':
        return (
          <>
            {details.studentName} {details.studentLastName} ha rifiutato la tua richiesta di modifica della lezione.
            Contatta la famiglia per proporre una alternativa ({details.email} oppure {details.phone}). Se non è
            possibile trovare una soluzione accettabile per te e la famiglia, contatta il referente (Giovanni Govoni:
            340 2817105).
          </>
        );
      default:
        return 'Notifica sconosciuta';
    }
  };

  return (
    <div className="relative">
      <motion.div
        style={{
          height: !isExpanded && !isAnimating ? '60px' : '60px',
        }}
        className="transition-all duration-600 ease-in-out"></motion.div>

      <motion.div
        className="bg-fpcred p-4 rounded-md shadow-lg"
        initial={{ height: '60px' }}
        animate={{
          height: isExpanded ? '700px' : '60px',
        }}
        style={{
          position: 'absolute',
          zIndex: 50,
          top: 0,
          left: 0,
          width: '100%',
          overflow: 'hidden',
        }}
        transition={{ duration: 0.6, ease: 'easeInOut' }}>
        <div className="flex justify-between items-center">
          <h3 className="text-white font-bold">{page}</h3>
          <button onClick={toggleExpansion}>
            {notifications.some((n) => n.status === 'unread') ? (
              <BellDot className="text-yellow-500" />
            ) : (
              <Bell className="text-white" />
            )}
          </button>
        </div>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: isExpanded ? 1 : 0,
            height: isExpanded ? 'auto' : 0,
          }}
          transition={{ duration: 0.4, ease: 'easeInOut' }}
          className="overflow-hidden">
          <div className="text-white mt-4 flex flex-col h-[600px]">
            <h2 className="text-lg font-bold mb-2">Le tue notifiche</h2>
            <div className="flex flex-col overflow-y-auto">
              {visibleNotifications.length > 0 ? (
                <ul>
                  {visibleNotifications.map((notification) => (
                    <li key={notification.id} className="bg-fpcwhite p-2 mb-2 text-black rounded-md">
                      {getNotificationMessage(notification)}
                      {notification.accept_link !== null &&
                        notification.reject_link !== null &&
                        notification.notificationDetails && (
                          <div className="flex gap-2 mt-2 justify-center">
                            <div className="flex flex-col md:flex-row items-center w-1/3 gap-4">
                              <button
                                className="bg-fpcpink text-fpcred font-bold shadow-md py-1 px-3 w-full h-full rounded hover:bg-gray-200 transition-colors"
                                onClick={() => {
                                  const modificationRequestId = new URL(
                                    notification.accept_link as string
                                  ).searchParams.get('lesson_modification_request_id');
                                  if (modificationRequestId) {
                                    api
                                      .post(`/lesson/modify/confirm`, {
                                        lesson_modification_request_id: modificationRequestId,
                                      })
                                      .then(() => {
                                        console.log(
                                          `Modifica della lezione ${modificationRequestId} confermata con successo.`
                                        );
                                        // Aggiorna lo stato a "accepted" in modo che la notifica venga nascosta
                                        setNotifications((prevNotifications) =>
                                          prevNotifications.map((n) =>
                                            n.id === notification.id ? { ...n, status: 'accepted' } : n
                                          )
                                        );
                                      })
                                      .catch((error) => {
                                        console.error(
                                          `Errore nella conferma della modifica ${modificationRequestId}`,
                                          error
                                        );
                                      });
                                  }
                                }}>
                                Accetta
                              </button>
                              <button
                                className="bg-fpcred text-white font-bold shadow-md py-1 px-3 w-full h-full rounded-md hover:bg-red-700 transition-colors"
                                onClick={() => {
                                  const modificationRequestId = new URL(
                                    notification.reject_link as string
                                  ).searchParams.get('lesson_modification_request_id');
                                  if (modificationRequestId) {
                                    api
                                      .post(`/lesson/modify/reject`, {
                                        lesson_modification_request_id: modificationRequestId,
                                      })
                                      .then(() => {
                                        console.log(
                                          `Modifica della lezione ${modificationRequestId} rifiutata con successo.`
                                        );
                                        // Aggiorna lo stato a "rejected" per nascondere la notifica
                                        setNotifications((prevNotifications) =>
                                          prevNotifications.map((n) =>
                                            n.id === notification.id ? { ...n, status: 'rejected' } : n
                                          )
                                        );
                                      })
                                      .catch((error) => {
                                        console.error(
                                          `Errore nel rifiuto della modifica ${modificationRequestId}`,
                                          error
                                        );
                                      });
                                  }
                                }}>
                                Rifiuta
                              </button>
                            </div>
                          </div>
                        )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Non ci sono notifiche al momento.</p>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default GenericComponent;
